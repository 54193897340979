import React, { useContext, useEffect, useState } from 'react'

import styles from './Header.module.scss'
import { arrMenu } from 'commons/constant'
import { Link, useLocation } from 'react-router-dom'
import MyButton from 'components/MyButton'
import useResizeWidth from 'controller/useResizeWidth'
import images from 'assets/images'
import { ActionContext } from 'controller/context/ActionProvider'

function Header() {
  const { funcContext } = useContext(ActionContext)
  const { pathname } = useLocation()

  const { handleShowModal } = funcContext
  const { positionY, isScrollDown, widthResize } = useResizeWidth()
  const isBackground = positionY >= 150
  const isTablet = widthResize <= 1280

  const [isShowMenu, setIsShowMenu] = useState(false)
  const [isHoverMenu, setIsHoverMenu] = useState('')

  const handleShowMenu = (status) => () => {
    setIsShowMenu(status)
  }

  const handleChooseLink = () => {
    setIsShowMenu(false)
    setIsHoverMenu('')
  }

  const onHover = (status) => () => {
    !isTablet && setIsHoverMenu(status)
  }

  const onClick = (status) => () => {
    if (isTablet) {
      if (isHoverMenu !== status) {
        setIsHoverMenu(status)
      } else {
        setIsHoverMenu('')
      }
    } else {
      return null
    }
  }

  useEffect(() => {
    if (widthResize >= 600) {
      setIsShowMenu(false)
    }
  }, [widthResize])

  useEffect(() => {
    if (isShowMenu) {
      document.querySelector('html').style.overflowY = 'hidden'
      document.querySelector('body').style.overflowY = 'hidden'
      document.querySelector('html').style.touchAction = 'none'
      document.querySelector('body').style.touchAction = 'none'
    } else {
      document.querySelector('html').style.overflowY = 'auto'
      document.querySelector('body').style.overflowY = 'auto'
      document.querySelector('html').style.touchAction = 'unset'
      document.querySelector('body').style.touchAction = 'unset'
    }
  }, [isShowMenu])

  return (
    <div className={styles.headerWrapper}>
      <div
        className={`header-wrapper header-wrapper--hide ${
          isBackground && 'header-wrapper--background'
        } ${!isScrollDown && 'header-wrapper--show'}`}
      >
        <div className='header-container'>
          <div className='header-left'>
            <Link to='/' onClick={handleChooseLink}>
              <span className='icon-web_logo_full icon-logo'></span>
            </Link>
          </div>

          <div className={`header-right ${isShowMenu && 'header-right--show'}`}>
            <div className='header-right-wrapper'>
              <div className='head-menu'>
                <Link to='/' onClick={handleChooseLink}>
                  <span className='icon-web_logo_full icon-logo-mobile'></span>
                </Link>

                <span
                  className='icon-web_close icon-menu-mobile'
                  onClick={handleShowMenu(false)}
                ></span>
              </div>

              {arrMenu.map((item, i) => {
                const menuActive = item.title === isHoverMenu

                return (
                  <div
                    className={`menu-header-container ${
                      item.title === 'Products' &&
                      'menu-header-container--products'
                    } ${menuActive && 'menu-header-container--show-menu'}`}
                    key={i}
                    onMouseEnter={onHover(item.title)}
                    onMouseLeave={onHover('')}
                    onClick={onClick(item.title)}
                  >
                    <div className='menu-head text-xs'>
                      {item.title}{' '}
                      <span className='icon-web_chevron_down icon-down'></span>
                    </div>

                    <div className='menu-container-wrapper'>
                      <div
                        className={`menu-container ${
                          item.title === 'Products' &&
                          'menu-container--products'
                        }`}
                      >
                        <div className='menu-items'>
                          {item.menus.map((itemChild, index) => {
                            const routerActive = itemChild.link === pathname

                            return (
                              <Link
                                to={itemChild.link}
                                className={`menu-item text-sm ${
                                  routerActive && 'menu-item--active'
                                }`}
                                key={index}
                                target={!itemChild.isNoBlank && '_blank'}
                                onClick={handleChooseLink}
                              >
                                {itemChild.icon && (
                                  <span
                                    className={`${itemChild.icon} icon-item`}
                                  ></span>
                                )}
                                {itemChild.name}
                              </Link>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}

              <MyButton
                type='small'
                className='btn-logo'
                url='/download'
                // onClick={handleShowModal}
              >
                Get Ramper <span className='icon-web_logo icon-logo'></span>
              </MyButton>
            </div>

            <div className='list-btn-wrapper'>
              <div className='title-btn text-xs'>Get Ramper</div>

              <div className='list-btn'>
                <MyButton className='btn-custom' type='secondary'>
                  <img src={images.apple} alt='' className='icon-app' />
                  iOS
                </MyButton>

                <MyButton
                  className='btn-custom'
                  type='secondary'
                  url='https://play.google.com/store/apps/details?id=ramper.wallet.crypto.finance&hl=en&gl=US'
                  isBlank
                >
                  <img src={images.android} alt='' className='icon-app' />
                  Android
                </MyButton>
              </div>
            </div>
          </div>

          <span
            className='icon-web_menu icon-menu'
            onClick={handleShowMenu(true)}
          ></span>
        </div>
      </div>
    </div>
  )
}

export default Header
