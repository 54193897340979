import React from 'react'

import styles from './MyButton.module.scss'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

function MyButton({
  className,
  children,
  url,
  isBlank = false,
  type,
  onClick,
}) {
  return (
    <div className={classNames(styles.myButton, className)}>
      {!url ? (
        <button className={`button button--${type}`} onClick={onClick}>
          {children}
        </button>
      ) : (
        <Link
          to={url}
          target={isBlank && '_blank'}
          className={`button button--${type}`}
        >
          {children}
        </Link>
      )}
    </div>
  )
}

export default MyButton
