import React, { useContext, useEffect, useRef, useState } from 'react'
import MyButton from 'components/MyButton'
import images from 'assets/images'
import { Switch } from 'components/Switch'
import Slider from 'react-slick'
import useResizeWidth from 'controller/useResizeWidth'
import ReactFinalMarquee from 'react-final-marquee'
import AppFuture from 'components/AppFuture'
import { ActionContext } from 'controller/context/ActionProvider'

import styles from './HomeScreen.module.scss'
import { Parallax } from 'react-scroll-parallax'

const arrSteps = [
  {
    step: 1,
    value: 0,
    image: images.screenLogin,
    imageDark: images.screenLoginDark,
    imageMobile: images.i14Login,
    title: 'Simplify Your Digital Life with Ramper',
    content:
      'No more hassle—just use your preferred social accounts and password to sign in. Gain instant access to all your digital assets in seconds.',
  },
  {
    step: 2,
    value: 1,
    image: images.screenNft,
    imageDark: images.screenNftDark,
    imageMobile: images.i14Nft,
    title: 'Transfer Your Digital Assets Anywhere, Anytime',
    content:
      'Sending and receiving crypto/NFTs globally is a breeze. All you need is the internet, your devices, and Ramper.',
  },
  {
    step: 3,
    value: 2,
    image: images.screenWallet,
    imageDark: images.screenWalletDark,
    imageMobile: images.i14Wallet,
    title: 'Manage and Grow Your Digital Assets with Ease and Security',
    content: `With Ramper's safe and simple interface, you can take control of your digital assets and start the Web3 adventure without anxiety.`,
  },
]

const arrChain = [
  {
    label: 'Viction',
  },
  {
    label: 'Ethereum',
  },
  {
    label: 'Polygon',
  },
  {
    label: 'BNB Chain',
  },
]

const arrTrusted = [
  {
    content: `Our gratitude to the Ramper team for seamlessly onboarding web2 users to web3 during our launch. Their commitment to user-friendly login options, multi-language support, and enhancing UI/UX through diverse login methods has been invaluable. We appreciate their efforts in making web3 accessible and user-friendly.
    `,
    avatar: images.user1,
    author: 'Jaehyun Park',
    position: 'CPO of Modhaus',
  },
  {
    content: `
    Ramper has been vital in connecting Web2 and Web3 audiences. They seamlessly create non-custodial wallets and facilitate Fiat payments, bridging the crypto gap in the music industry. Ramper simplifies crypto for everyone and their outstanding support sets them apart, making them a valuable partner. Highly recommended.
    `,
    avatar: images.user2,
    author: 'Justin Burkholder',
    position: 'Co-founder of Music Feast',
  },
  {
    content: `
    The ​Mintickt and ​Ramper partnership has improved our ticketing platform. Ramper's social login  solution simplifies user onboarding, allowing wallet creation via Google, Facebook, or email. Users can also top up their wallet with a credit card, enhancing convenience. This collaboration streamlines onboarding, enhances accessibility, and fosters Mintickt's growth and success.
    `,
    avatar: images.user3,
    author: 'Kemal',
    position: 'Co-founder of Mintickt',
  },
]

function HomeScreen() {
  const { funcContext } = useContext(ActionContext)

  const screenSlideRef = useRef(null)
  const trustedRef = useRef(null)
  const trustedListRef = useRef(null)
  const trustedWrapperRef = useRef(null)

  const { widthResize } = useResizeWidth()

  const [stepScreen, setStepScreen] = useState(arrSteps[0])
  const [isChange, setIsChange] = useState(false)
  const [isDark, setIsDark] = useState(false)
  const [scrollPositionX, setScrollPositionX] = useState(0)
  const [isDisableBtn, setIsDisableBtn] = useState({
    left: true,
    right: false,
  })
  const [progress, setProgress] = useState(0)
  const progressConvert = (progress * 100) / 1.45
  // const progressConvert = progress * 100 >= 50 ? 50 : progress * 100

  const isTabletSmall = widthResize <= 960
  const isTablet = widthResize <= 1280
  const widthSlide =
    (window.innerWidth - trustedWrapperRef.current?.clientWidth) / 2 - 80

  const handleChoose = (item) => () => {
    setIsChange(true)
    screenSlideRef.current.slickGoTo(item.value)

    setTimeout(() => {
      setStepScreen(item)
      setIsChange(false)
    }, 200)
  }

  const handleTheme = () => {
    setIsDark(!isDark)
  }

  const handleDirection = (status, number) => () => {
    trustedListRef.current.scrollLeft += number
    const positionX = scrollPositionX + number
    // const checkPosition = positionX >= 1046
    const checkPosition = positionX >= 960

    if (status === 'right') {
      if (!checkPosition) {
        setScrollPositionX(scrollPositionX + number)
        setIsDisableBtn({
          left: false,
          right: false,
        })
      } else {
        setIsDisableBtn({
          left: false,
          right: true,
        })
        return null
      }
    } else {
      if (!(positionX < 0)) {
        setScrollPositionX(scrollPositionX + number)
        setIsDisableBtn({
          left: false,
          right: false,
        })
      } else {
        setIsDisableBtn({
          left: true,
          right: false,
        })
        return null
      }
    }
  }

  useEffect(() => {
    const elm = document.querySelector('.trusted-list-container')
  }, [])

  const settingScreen = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'slide-screen',
  }

  return (
    <div className={styles.homeScreen}>
      <div className='banner-home'>
        <h2 className='banner-title'>
          A Go–To Digital Wallet to Empower the Open Economy
          {/* A Super
          Convenient Wallet to Empower the Open Economy */}
        </h2>

        <div className='banner-description text-lg'>
          Discover digital economy with no required difficult seed phrases or
          keys — seamlessly connect, manage, and expand your digital assets
          securely.
        </div>

        <div className='button-action'>
          <MyButton url='/download'>
            Get Ramper <span className='icon-web_logo'></span>
          </MyButton>
        </div>
      </div>

      <div className='box-image box-image--img-1'>
        <img src={images.walletImage} alt='' className='mobile-img-banner' />

        <div className='box-mark'>
          <img src={images.brandMarkPlus} alt='' className='brand-mark' />
        </div>

        <img src={images.ramperText} alt='' className='image-text' />
      </div>

      <div className='ramper-app'>
        <img src={images.appScreen} alt='' className='app-screen' />
      </div>

      <div className='box-image box-image--img-2'>
        <div className='box-wrapper'>
          <div className='text-title'>
            <h3>One Wallet</h3>
            <h1>Endless Possibilities</h1>
          </div>

          <div
            className='img-wallet-2'
            style={{ '--progress': `-${progressConvert}%` }}
          >
            <Parallax onProgressChange={(progress) => setProgress(progress)}>
              <img src={images.appScreen2} alt='' className='img-wallet-icon' />
            </Parallax>
          </div>
        </div>
      </div>

      <div className='mobile-screen'>
        <div className='circle circle--delay-1'></div>
        <div className='circle circle--delay-2'></div>
        <div className='circle circle--delay-3'></div>

        <div className='mobile-app-wrapper'>
          <div className='mobile-app'>
            <img src={images.mobile} alt='' className='img-mobile' />

            <div
              className={`mobile-app-screen ${
                isDark && 'mobile-app-screen--dark-mode'
              }`}
            >
              {isDark ? (
                <Slider {...settingScreen} ref={screenSlideRef}>
                  <img
                    src={images.screenLoginDark}
                    alt=''
                    className={`screen-app ${isChange && 'screen-app--change'}`}
                  />
                  <img
                    src={images.screenWalletDark}
                    alt=''
                    className={`screen-app ${isChange && 'screen-app--change'}`}
                  />
                  <img
                    src={images.screenNftDark}
                    alt=''
                    className={`screen-app ${isChange && 'screen-app--change'}`}
                  />
                </Slider>
              ) : (
                <Slider {...settingScreen} ref={screenSlideRef}>
                  <img
                    src={images.screenLogin}
                    alt=''
                    className={`screen-app ${isChange && 'screen-app--change'}`}
                  />
                  <img
                    src={images.screenWallet}
                    alt=''
                    className={`screen-app ${isChange && 'screen-app--change'}`}
                  />
                  <img
                    src={images.screenNft}
                    alt=''
                    className={`screen-app ${isChange && 'screen-app--change'}`}
                  />
                </Slider>
              )}
            </div>
          </div>
        </div>

        <div className='content-text'>
          <div className='mode-box'>
            {isTabletSmall && (
              <Switch
                onToggle={handleTheme}
                isToggle={isDark}
                className='switch-mode'
              />
            )}
          </div>

          <div
            className={`content-text-wrapper ${
              !isChange && 'content-text-wrapper--show'
            }`}
          >
            <h3>{stepScreen.title}</h3>

            <div className='text-description'>{stepScreen.content}</div>
          </div>

          <div className='list-step'>
            {!isTabletSmall && (
              <Switch onToggle={handleTheme} isToggle={isDark} />
            )}

            <div className='steps-container'>
              {arrSteps.map((item, i) => {
                const isStepActive = item.step === stepScreen.step

                return (
                  <div
                    className={`step-wrapper ${
                      isStepActive && 'step-wrapper--active'
                    }`}
                    key={i}
                    onClick={handleChoose(item)}
                  >
                    <div
                      className={`step-item ${
                        isStepActive && 'step-item--active'
                      }`}
                    >
                      {item.step}
                    </div>

                    <div
                      className={`line-space ${
                        isStepActive && 'line-space--active'
                      }`}
                    ></div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div className='mobile-screen-mobile'>
        {arrSteps.map((item, i) => {
          return (
            <div className='item-screen' key={i}>
              <img src={item.imageMobile} alt='' className='img-mobile' />

              <div className='text-body'>
                <h3 className='title-text'>{item.title}</h3>

                <div className='description-text'>{item.content}</div>
              </div>
            </div>
          )
        })}
      </div>

      <div className='block-text'>
        <div className='block-text-container'>
          <div className='item-block item-block--title'>
            <h2>
              One Wallet.
              <br />
              Multiple Blockchains.
            </h2>
          </div>

          <h2 className='title-mobile title-mobile--item-1'>One Wallet.</h2>

          <div className='item-block item-block--name-chain'>
            <div className='item-icon'>
              <span className='icon-web_arrow_right icon-arrow'></span>
            </div>

            <div className='list-text-wrapper'>
              <div className='list-text'>
                <ReactFinalMarquee
                  // className='marquee-text'
                  //  direction='up'
                  speed='40'
                  className='marquee-customer-class'
                  direction='bottomToTop'
                  repeat='4'
                  pauseOnHover={false}
                >
                  {arrChain.map((item, i) => {
                    return (
                      <div className='text-content' key={i}>
                        {item.label}
                      </div>
                    )
                  })}
                </ReactFinalMarquee>
              </div>
            </div>

            <div className='line-mobile'></div>
          </div>

          <h2 className='title-mobile title-mobile--item-2'>
            Multiple Blockchains.
          </h2>
        </div>
      </div>

      <div className='trusted-wrapper'>
        <div className='trusted-container' ref={trustedWrapperRef}>
          <div className='trusted-title'>
            <h2>Trusted by Industry Pioneers</h2>

            <div className='box-direction box-direction--no-mobile'>
              <span
                className={`icon-web_arrow_left icon-arrow ${
                  isDisableBtn.left && 'icon-arrow--left-disable'
                }`}
                id='arrow-left'
                onClick={handleDirection('left', -500)}
              ></span>
              <span
                className={`icon-web_arrow_right icon-arrow ${
                  isDisableBtn.right && 'icon-arrow--right-disable'
                }`}
                id='arrow-right'
                onClick={handleDirection('right', 500)}
              ></span>
            </div>
          </div>

          <div
            className='trusted-list-wrapper'
            style={{ '--width-space': `${widthSlide}px` }}
            ref={trustedRef}
          >
            <div className='trusted-list-container' ref={trustedListRef}>
              {arrTrusted.map((item, i) => {
                return (
                  <div className='item-trusted' key={i}>
                    <div className='item-content'>
                      <div
                        className='content-text'
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      ></div>

                      <div className='author-box'>
                        <div className='author-info'>
                          <div className='author-info__img'>
                            <img
                              src={item.avatar}
                              alt=''
                              className='icon-author'
                            />
                          </div>

                          <div className='text-info text-sm'>
                            {item.author}

                            <div className='text-position text-xs'>
                              {item.position}
                            </div>
                          </div>
                        </div>

                        {/* <span className='icon-web_twitter icon-social'></span> */}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <div className='box-direction box-direction--mobile'>
            <span
              className={`icon-web_arrow_left icon-arrow ${
                isDisableBtn.left && 'icon-arrow--left-disable'
              }`}
              id='arrow-left'
              onClick={handleDirection('left', -344)}
            ></span>
            <span
              className={`icon-web_arrow_right icon-arrow ${
                isDisableBtn.right && 'icon-arrow--right-disable'
              }`}
              id='arrow-right'
              onClick={handleDirection('right', 344)}
            ></span>
          </div>
        </div>
      </div>

      <div className='future-screen'>
        <img
          src={images.ipadApp}
          alt=''
          className='device-screen device-screen--tablet'
        />
        <div className='device-screen device-screen--mobile'>
          <Parallax speed={-5}>
            <img src={images.walletImage} alt='' className='device-img' />
          </Parallax>
        </div>

        <div className='body-content'>
          <h2>Take the First Step towards a Better Future!</h2>

          <AppFuture />
        </div>
      </div>
    </div>
  )
}

export default HomeScreen
