import React from 'react'

import styles from '../RamperPage.module.scss'
import MyButton from 'components/MyButton'
import images from 'assets/images'

function RamperNft() {
  return (
    <div className={styles.ramperPageWrapper}>
      <div className='ramper-container'>
        <div className='section-title-wrapper'>
          <div className='section-title'>
            <h2>
              Fast NFT Checkout Supporting <br /> Both Crypto & Fiat
            </h2>

            <div className='text-description text-lg'>
              Mint and manage NFTs with peace of mind.
            </div>

            <div className='btn-action'>
              <MyButton
                className='btn-custom btn-custom--nft-btn'
                url='https://example.ramper.xyz/v2/demo/checkout'
                isBlank
              >
                Try Demo <span className='icon-web_logo icon-logo'></span>
              </MyButton>

              <MyButton
                className='btn-custom btn-custom--nft-btn'
                type='secondary'
                url='https://docs.ramper.xyz/ramper-nft-checkout-sdk/about-ramper-nft-checkout'
                isBlank
              >
                Learn More
              </MyButton>
            </div>
          </div>
        </div>

        <div className='section-content-wrapper'>
          <div className='section-content'>
            {/* <h3 className='title-content'>
              The finest NFT <br /> purchase experience
            </h3> */}

            <div className='content-body'>
              <div className='content-item'>
                <div className='item-content item-content--img item-content--img-nft'>
                  <img src={images.nftScreen} alt='' className='icon-item' />
                </div>

                <div className='item-content item-content--text'>
                  <h3 className='item-title'>Fiat On-ramp Solution</h3>

                  <div className='item-text'>
                    <div className='text'>
                      <div className='dot'></div>
                      One-step solution to purchase NFT with both crypto and
                      fiat.
                    </div>

                    <div className='text'>
                      <div className='dot'></div>
                      Available options for Credit and Debit cards.
                    </div>

                    <div className='text'>
                      <div className='dot'></div>
                      Customizable UI and purchase flow.
                    </div>
                  </div>
                </div>
              </div>

              <div className='content-item-ipad'>
                <div className='item-content-ipad'>
                  <img src={images.ipadNft} alt='' className='icon-item' />
                </div>

                <div className='item-content-ipad item-content-ipad--text'>
                  <h3 className='item-text'>
                    Effortless <br /> Administration
                  </h3>

                  <div className='item-text'>
                    <div className='text'>
                      <div className='dot'></div>
                      Hassle-fee management with the Ramper Dashboard.
                    </div>

                    <div className='text'>
                      <div className='dot'></div>
                      Available options for C redit and Debit cards.
                    </div>

                    <div className='text'>
                      <div className='dot'></div>
                      Customizable UI and purchase flow.
                    </div>

                    {/* <div className='text'>
                      <div className='dot'></div>
                      Scalable, user-friendly, and safe for developers.
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='section-footer-wrapper'>
          <div className='section-footer'>
            {/* <div className='text-description text-lg'>
              Our SDKs do the heavy lifting <br /> so you can focus on what you
              do best:
            </div> */}

            <h3 className='ft-title ft-title--nft'>
              Make NFTs Easy and Accessible
            </h3>

            <MyButton
              isBlank
              type='secondary'
              url='https://docs.ramper.xyz/ramper-nft-checkout-sdk/about-ramper-nft-checkout'
            >
              Get Started with Ramper{' '}
              <span className='icon-web_logo icon-logo-footer'></span>
            </MyButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RamperNft
