import React from 'react'

import styles from '../RamperPage.module.scss'
import MyButton from 'components/MyButton'
import images from 'assets/images'

function RamperSdk() {
  return (
    <div className={styles.ramperPageWrapper}>
      <div className='ramper-container'>
        <div className='section-title-wrapper'>
          <div className='section-title'>
            <h2>Ramper SDKs Tailored for Every Stack</h2>

            <div className='text-description text-lg'>
              Save months of Dev work with our Free Web3 SDKs - the programming
              languages you need to simplify Web3 complexity.
            </div>

            <div className='btn-action'>
              <MyButton
                className='btn-custom'
                url='https://example.ramper.xyz/v1/demo'
                isBlank
              >
                Try Demo <span className='icon-web_logo icon-logo'></span>
              </MyButton>

              <MyButton
                className='btn-custom'
                type='secondary'
                url='https://docs.ramper.xyz/'
                isBlank
              >
                Build With Us
              </MyButton>
            </div>
          </div>
        </div>

        <div className='section-content-wrapper'>
          <div className='section-content'>
            {/* <h3 className='title-content'>
              Onboard with Ease, <br /> Innovate with Freedom
            </h3> */}

            <div className='content-body'>
              <div className='content-item'>
                <div className='item-content item-content--img'>
                  <img src={images.loginScreen} alt='' className='icon-item' />
                </div>

                <div className='item-content item-content--text'>
                  <h3 className='item-title'>
                    Social Login Solution for Users without Wallet
                  </h3>

                  <div className='item-text'>
                    <div className='text'>
                      A non-custodial wallet that doesn't require downloads,
                      browser extensions, nor seed phrases to get started.
                    </div>

                    <div className='text'>
                      <div className='dot'></div>
                      Login in a minute using email, Single Sign-On.
                    </div>

                    <div className='text'>
                      <div className='dot'></div>
                      Non-custodial wallet is generated behind the scene.
                    </div>
                  </div>
                </div>
              </div>

              <div className='content-item-ipad'>
                <div className='item-content-ipad item-content-ipad--img-sdk'>
                  <img
                    src={images.imgSdk}
                    alt=''
                    className='icon-item icon-item--desktop icon-item--img-sdk'
                  />
                  <img
                    src={images.imgSdkMobile}
                    alt=''
                    className='icon-item icon-item--mobile icon-item--img-sdk'
                  />
                </div>

                <div className='item-content-ipad item-content-ipad--text'>
                  <h3 className='item-text'>
                    {/* RPMS Architecture for Developers to Rely on */}
                    Reliable EMPC Architecture for Onchain Applications to Scale
                  </h3>

                  <div className='item-text'>
                    <div className='text-description'>
                      {/* A superior key management architecture - Restorative
                      Performant Multiencryption System (RPMS) to securely
                      acquire the next billion users. */}
                      A superior key management system with Embedded Multi-Party
                      Computation (EMPC) to onboard the next billion users to
                      onchain economy
                    </div>

                    <div className='text'>
                      <div className='dot'></div>
                      {/* Robust security measures. */}
                      Genuine Decentralization
                    </div>

                    <div className='text'>
                      <div className='dot'></div>
                      {/* Prioritized speed and portability. */}
                      Powerful Self-custody
                    </div>

                    <div className='text'>
                      <div className='dot'></div>
                      {/* Scalable, user-friendly, and safe for developers. */}
                      Solid Security Measures
                    </div>

                    <div className='text'>
                      <div className='dot'></div>
                      Efficient Performance
                    </div>

                    <div className='text'>
                      <div className='dot'></div>
                      Multichain Powered
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='section-footer-wrapper'>
          <div className='section-footer'>
            <h3 className='ft-title'>Building Great Products</h3>

            <div className='text-description text-lg'>
              Our SDKs do the heavy lifting so you can focus on what you do
              best.
            </div>

            <MyButton type='secondary' isBlank url='https://docs.ramper.xyz/'>
              Get Started for Free{' '}
              <span className='icon-web_logo icon-logo-footer'></span>
            </MyButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RamperSdk
