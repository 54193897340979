import ModalGet from 'components/ModalGet'
import React from 'react'

function DownloadScreen() {
  return (
    <div>
      <ModalGet />
    </div>
  )
}

export default DownloadScreen
