import ModalGet from 'components/ModalGet'
import { createContext } from 'react'

export const ActionContext = createContext()

function ActionProvider({ children }) {
  const handleShowModal = () => {
    window.openModal({
      content: <ModalGet />,
    })
  }

  const stateContext = {}
  const funcContext = { handleShowModal }

  const values = {
    stateContext,
    funcContext,
  }

  return (
    <ActionContext.Provider value={values}>{children}</ActionContext.Provider>
  )
}

export default ActionProvider
