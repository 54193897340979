import React from 'react'

import styles from './Switch.module.scss'

export const Switch = ({
  isToggle,
  onToggle,
  className,
  isBorder = false,
  disabled = false,
  label,
}) => {
  const cxInputCheckedDisabled =
    isToggle && disabled ? 'my-switch--checked-disbaled' : ''

  return (
    <div className={styles.switchWrapper}>
      <div className='switch-container'>
        <label
          className={`my-switch ${
            isBorder && 'my-switch__border'
          } ${className} ${cxInputCheckedDisabled}`}
        >
          <input
            type='checkbox'
            checked={isToggle}
            onChange={onToggle}
            disabled={disabled}
          />
          <span className={`slider`}></span>

          <span className='icon-web_dark_mode icon-mode icon-mode--dark'></span>
          <span className='icon-web_light_mode icon-mode icon-mode--light'></span>
        </label>

        <div className='switch-text'>{label}</div>
      </div>
    </div>
  )
}
