import React, { useEffect, useState } from 'react'

import './Modal.scss'

function Modal() {
  const [contentModal, setContentModal] = useState()
  const [isShowModal, setIsShowModal] = useState(false)

  const openModal = (prop) => {
    const { content, ...props } = prop
    setContentModal(content)
    setIsShowModal(true)
  }

  const closeModal = () => {
    setIsShowModal(false)
  }

  useEffect(() => {
    window.openModal = openModal
    window.closeModal = closeModal
  }, [])

  useEffect(() => {
    if (isShowModal) {
      document.querySelector('html').style.overflowY = 'hidden'
      document.querySelector('body').style.overflowY = 'hidden'
      document.querySelector('html').style.touchAction = 'none'
      document.querySelector('body').style.touchAction = 'none'
    } else {
      document.querySelector('html').style.overflowY = 'auto'
      document.querySelector('body').style.overflowY = 'auto'
      document.querySelector('html').style.touchAction = 'unset'
      document.querySelector('body').style.touchAction = 'unset'
    }
  }, [isShowModal])

  return (
    <div className={`modal-wrapper ${isShowModal && 'modal-wrapper--show'}`}>
      <div className='modal-background' onClick={closeModal}></div>
      <div className='modal-container'>{contentModal}</div>
    </div>
  )
}

export default Modal
