import 'normalize.css'
import Header from 'layouts/Header'
import { Route, Routes, useLocation } from 'react-router-dom'
import HomeScreen from 'pages/HomeScreen'
import Footer from 'layouts/Footer'
import { ParallaxProvider } from 'react-scroll-parallax'
import Modal from 'components/Modal'
import RamperSdk from 'pages/RamperPage/sdk'
import RamperNft from 'pages/RamperPage/nft'
import { useEffect } from 'react'
import DownloadScreen from 'pages/DowloadScreen'

function App() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
    })
  }, [pathname])

  return (
    <div className='App'>
      <Header />

      <ParallaxProvider>
        <Routes>
          <Route path='/wallet' element={<HomeScreen />} />
          <Route path='/' element={<RamperSdk />} />
          <Route path='/nft' element={<RamperNft />} />
          <Route path='/download' element={<DownloadScreen />} />
        </Routes>
      </ParallaxProvider>

      <Footer />

      <Modal />
    </div>
  )
}

export default App
