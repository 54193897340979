import React from 'react'

import styles from './Footer.module.scss'
import MyButton from 'components/MyButton'
import images from 'assets/images'
import { Link, useLocation } from 'react-router-dom'
import useResizeWidth from 'controller/useResizeWidth'

const arrMenuMobile = [
  {
    title: 'Products',
    menus: [
      {
        name: 'Ramper SDK',
        link: '/sdk',
      },
      {
        name: 'Ramper NFT Checkout',
        link: '/nft',
      },
    ],
  },
  {
    title: 'Resource',
    menus: [
      {
        name: 'Docs',
        link: 'https://docs.ramper.xyz/',
        isBlank: true,
      },
      {
        name: 'Blog',
        link: 'https://blog.ramper.xyz/',
        isBlank: true,
      },
    ],
  },
  {
    title: 'Legal',
    menus: [
      {
        name: 'Terms & Conditions',
        link: 'https://docs.ramper.xyz/terms-and-service',
        isBlank: true,
      },
      {
        name: 'Privacy Policy',
        link: 'https://docs.ramper.xyz/privacy-policy',
        isBlank: true,
      },
    ],
  },
]

function Footer() {
  const { widthResize } = useResizeWidth()
  const { pathname } = useLocation()

  const isSdk = pathname === '/sdk'
  const isNft = pathname === '/nft'

  const arrMenu = [
    {
      title: 'Products',
      menus: [
        {
          name: 'Ramper Wallet',
          link: '/wallet',
        },
        {
          name: 'Ramper NFT Checkout SDK',
          link: '/nft',
        },
      ],
    },
    {
      title: 'Legal',
      menus: [
        {
          name: 'Terms & Conditions',
          link: isSdk
            ? 'https://docs.ramper.xyz/ramper-sdk/terms-and-conditions'
            : isNft
            ? 'https://docs.ramper.xyz/ramper-nft-checkout-sdk/terms-of-service'
            : 'https://docs.ramper.xyz/ramper-wallet/terms-of-service',
          isBlank: true,
        },
        {
          name: 'Privacy Policy',
          link: isSdk
            ? 'https://docs.ramper.xyz/ramper-sdk/privacy-policy'
            : isNft
            ? 'https://docs.ramper.xyz/ramper-nft-checkout-sdk/privacy-policy'
            : 'https://docs.ramper.xyz/ramper-wallet/privacy-policy',
          isBlank: true,
        },
      ],
    },
    {
      title: 'Resource',
      menus: [
        {
          name: 'Docs',
          link: 'https://docs.ramper.xyz/',
          isBlank: true,
        },
        {
          name: 'Blog',
          link: 'https://blog.ramper.xyz/',
          isBlank: true,
        },
      ],
    },
  ]

  const arrMenuConvert = widthResize <= 600 ? arrMenuMobile : arrMenu

  const handleClick = (status) => () => {
    if (!status) {
      window.scrollTo({
        top: 0,
      })
    }
  }

  return (
    <div className={styles.footerWrapper}>
      <div className='footer-container'>
        <div className='footer-item'>
          <h5>Stay Informed & Connected</h5>

          <div className='text-sm ft-description'>
            Get the latest updates and be a part of our dynamic community!
          </div>

          <div className='input-footer'>
            <input
              type='text'
              className='input-content'
              placeholder='Enter your email'
            />
            <MyButton
              url='https://coin98.typeform.com/to/vPfWzbgk'
              isBlank
              type='small'
              className='btn-footer'
            >
              Subscribe
            </MyButton>
          </div>

          <div className='footer-social footer-social--desktop'>
            <div className='footer-text text-xs'>© 2023 Ramper Labs, LLC</div>
          </div>
        </div>

        <div className='footer-right'>
          <div className='menu-content'>
            {arrMenuConvert.map((item, i) => {
              return (
                <div className='item-menu' key={i}>
                  <div className='item-menu__title'>{item.title}</div>

                  <div className='item-menu__list'>
                    {item.menus.map((itemChild, index) => {
                      const routerActive = pathname === itemChild.link

                      return (
                        <Link
                          to={itemChild.link}
                          target={itemChild.isBlank && '_blank'}
                          className={`menu ${routerActive && 'menu--active'}`}
                          key={index}
                          onClick={handleClick(item.isBlank)}
                        >
                          {itemChild.name}
                        </Link>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>

          <div className='list-social list-social--desktop'>
            <div className='social-title'>Contact Us</div>

            <div className='social-list'>
              <Link to='mailto:Hi@ramper.xyz' target='_blank'>
                <span className='icon-web_mail icon-social'></span>
              </Link>
              <Link to='https://twitter.com/ramper_xyz' target='_blank'>
                <span className='icon-web_twitter icon-social'></span>
              </Link>
              <Link to='https://t.me/+1o0oCz2saPM1Njll' target='_blank'>
                <span className='icon-web_telegram icon-social'></span>
              </Link>
              <Link to='https://discord.gg/rKYwSqX8FE' target='_blank'>
                <span className='icon-web_discord icon-social'></span>
              </Link>
              <Link
                to='https://www.facebook.com/profile.php?id=61551665860024'
                target='_blank'
              >
                <span className='icon-web_facebook icon-social'></span>
              </Link>
            </div>
          </div>

          <div className='footer-social footer-social--mobile'>
            <div className='list-social'>
              <div className='social-title'>Contact Us</div>

              <div className='social-list'>
                <Link to='mailto:Hi@ramper.xyz' target='_blank'>
                  <span className='icon-web_mail icon-social'></span>
                </Link>
                <Link to='https://twitter.com/ramper_xyz' target='_blank'>
                  <span className='icon-web_twitter icon-social'></span>
                </Link>
                <Link to='https://t.me/+1o0oCz2saPM1Njll' target='_blank'>
                  <span className='icon-web_telegram icon-social'></span>
                </Link>
                <Link to='https://discord.gg/rKYwSqX8FE' target='_blank'>
                  <span className='icon-web_discord icon-social'></span>
                </Link>
                <Link
                  to='https://www.facebook.com/profile.php?id=61551665860024'
                  target='_blank'
                >
                  <span className='icon-web_facebook icon-social'></span>
                </Link>
              </div>
            </div>

            <div className='footer-text text-xs'>© 2023 Ramper Labs, LLC</div>
          </div>
        </div>

        <img src={images.ramperText2} alt='' className='img-text' />
      </div>
    </div>
  )
}

export default Footer
