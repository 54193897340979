import images from 'assets/images'

const arrMenu = [
  {
    title: 'Products',
    menus: [
      {
        name: 'Ramper Wallet',
        link: '/wallet',
        // icon: 'icon-web_contacts',
        isNoBlank: true,
      },
      {
        name: 'Ramper NFT Checkout SDK',
        link: '/nft',
        // icon: 'icon-web_contacts',
        isNoBlank: true,
      },
    ],
  },
  {
    title: 'Resource',
    menus: [
      {
        name: 'Docs',
        link: 'https://docs.ramper.xyz/',
        icon: 'icon-web_docs',
      },
      {
        name: 'Blog',
        link: 'https://blog.ramper.xyz/',
        icon: 'icon-web_blog',
      },
    ],
  },
  {
    title: 'Community',
    menus: [
      {
        name: 'X',
        link: 'https://twitter.com/ramper_xyz',
        icon: 'icon-web_twitter',
      },
      {
        name: 'Telegram',
        link: 'https://t.me/+1o0oCz2saPM1Njll',
        icon: 'icon-web_telegram',
      },
      {
        name: 'Discord',
        link: 'https://discord.gg/rKYwSqX8FE',
        icon: 'icon-web_discord',
      },
      {
        name: 'Facebook',
        link: 'https://www.facebook.com/profile.php?id=61551665860024',
        icon: 'icon-web_facebook',
      },
    ],
  },
]

const arrApp = [
  {
    title: 'Download Ramper Extension',
    isHideMobile: true,
    apps: [
      {
        name: 'Chrome',
        link:
          'https://chrome.google.com/webstore/detail/ramper-wallet/nbdhibgjnjpnkajaghbffjbkcgljfgdi?hl=vi',
        img: images.chrome,
      },
      {
        name: 'Edge',
        link: '/',
        img: images.edge,
        isComing: true,
      },
      {
        name: 'Firefox',
        link: '/',
        img: images.firefox,
        isComing: true,
      },
    ],
  },
  {
    title: 'Download Ramper App',
    apps: [
      {
        name: 'iOS',
        link: 'https://apps.apple.com/vn/app/ramper-wallet/id6461721561',
        img: images.apple,
      },
      {
        name: 'Android',
        link:
          'https://play.google.com/store/apps/details?id=ramper.wallet.crypto.finance&hl=en&gl=US',
        img: images.android,
      },
    ],
  },
]

export { arrMenu, arrApp }
