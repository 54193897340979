import React from 'react'

import './ModalGet.scss'
import AppFuture from 'components/AppFuture'
import images from 'assets/images'

function ModalGet() {
  return (
    <div className='modal-get-wrapper'>
      {/* <div className='modal-get-head'>
        <span
          className='icon-web_close icon-close'
          onClick={() => window.closeModal()}
        ></span>
      </div> */}

      <div className='modal-get-body'>
        <div className='body-text'>
          <h3>
            Take the First Step Towards a <span>Better Future</span>!
          </h3>

          <div className='body-app'>
            <AppFuture />
          </div>
        </div>

        <div className='img-app'>
          <img
            src={images.ipadApp}
            alt=''
            className='app-screen app-screen--tablet'
          />

          <img
            src={images.walletImage}
            alt=''
            className='app-screen app-screen--mobile'
          />
        </div>
      </div>
    </div>
  )
}

export default ModalGet
