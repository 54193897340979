const images = {
  walletImage: require('./home/wallet-image.png'),
  ramperText: require('./home/ramper-text.png'),
  ramperText2: require('./home/ramper-text-2.png'),
  appScreen: require('./home/app-screen-1.png'),
  appScreen2: require('./home/app-screen-2.png'),

  screenNft: require('./home/screen-nft.png'),
  screenNftDark: require('./home/screen-nft-dark.png'),
  screenWallet: require('./home/screen-wallet.png'),
  screenWalletDark: require('./home/screen-wallet-dark.png'),
  screenLogin: require('./home/screen-login.png'),
  screenLoginDark: require('./home/screen-login-dark.png'),

  mobile: require('./home/mobile.png'),
  frontCam: require('./home/front-camera.png'),
  ipadApp: require('./home/ipad-app.png'),

  authorExample: require('./home/example/author-1.png'),
  authorExample2: require('./home/example/author-2.png'),
  authorExample3: require('./home/example/author-3.png'),

  chrome: require('./home/chrome.png'),
  edge: require('./home/edge.png'),
  firefox: require('./home/firefox.png'),
  apple: require('./home/apple.png'),
  android: require('./home/android.png'),

  i14Login: require('./home/i14-login.png'),
  i14Wallet: require('./home/i14-wallet.png'),
  i14Nft: require('./home/i14-nft.png'),
  brandMarkPlus: require('./home/brand-mark-plus.png'),

  user1: require('./home/user-1.png'),
  user2: require('./home/user-2.png'),
  user3: require('./home/user-3.png'),

  loginScreen: require('./home/login-screen.png'),
  ipadNft: require('./home/ipadNft.png'),
  imgSdk: require('./home/imgSdk.png'),
  imgSdkMobile: require('./home/imgSdkMobile.png'),

  nftScreen: require('./ramperPage/nft-screen.png'),

  // example
  imgExample: require('./ramperPage/img-example.png'),
}

export default images
