import { arrApp } from 'commons/constant'
import useResizeWidth from 'controller/useResizeWidth'
import React from 'react'
import { Link } from 'react-router-dom'

import './AppFuture.scss'

function AppFuture() {
  const { widthResize } = useResizeWidth()

  return (
    <div className='app-future'>
      {arrApp.map((item, i) => {
        return (
          <div
            className={`app-items ${
              item.isHideMobile && widthResize <= 600 && 'app-items--hide'
            }`}
            key={i}
          >
            <div className='app-items__title'>{item.title}</div>

            <div className='app-items__app'>
              {item.apps.map((itemChild, index) => {
                return (
                  <Link
                    className={`item-app ${
                      itemChild.isComing && 'item-app--coming'
                    }`}
                    to={itemChild.link}
                    target={itemChild.link.includes('https') && '_blank'}
                    key={index}
                  >
                    {itemChild.isComing && (
                      <div className='coming-soon'>Comming Soon</div>
                    )}
                    <img src={itemChild.img} alt='' className='icon-app' />
                    {itemChild.name}
                  </Link>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default AppFuture
